import { Button, Grid, Paper, Typography, useMediaQuery, useTheme } from "@mui/material"
import { Box, Container, Stack } from "@mui/system"
import CategoriesTable from "./CategoriesTable"
import { Category, CategoryInput } from "../../../../models/category";
import AddIcon from '@mui/icons-material/Add';
import AddCategoryModal from "./AddCategoryModal";
import { useEffect, useState } from "react";
import { useAdminContext } from "../AdminContext";
import CategoryService from "../../../../api/categoryService";
import apiConfigInstance from "../../../../api/apiConfig";
import { ErrorOutline } from "@mui/icons-material";
import { useError } from "../../../errors/ErrorContext";
import { AxiosError } from "axios";

const AdminCategoriesPage = () => {
    const theme = useTheme();
    const { handleError } = useError()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { categoriesState, setCategoriesState } = useAdminContext(); // Use the useAdminContext hook

    const [openModal, setOpenModal] = useState<boolean>(false);

    const categoryService = new CategoryService(apiConfigInstance);

    const addCategory = (newCategory: CategoryInput) => {
        categoryService.createCategory(newCategory).then((createdCategory: Category) => {
            setCategoriesState((prevState) => ({
                ...prevState,
                categories: [...prevState.categories, createdCategory],
                loading: false
            }));
        })
    }

    const updateCategory = async (updatedCategory: Category) => {
        try {
            // Call the category service to update the category
            const updatedCategoryResponse = await categoryService.updateCategory(updatedCategory.id, updatedCategory);

            // Find the index of the updated category in the categories array
            const categoryIndex = categoriesState.categories.findIndex(category => category.id === updatedCategoryResponse.id);

            // Create a new array with the updated category replacing the old one at the found index
            const updatedCategories = [...categoriesState.categories];
            updatedCategories[categoryIndex] = updatedCategoryResponse;

            // Update the state with the new array of categories
            setCategoriesState((prevState) => ({
                ...prevState,
                categories: updatedCategories,
                loading: false
            }));

            // Optionally, you can show a notification or perform any other action upon successful update
            console.log('Category updated successfully');
        } catch (error) {
            // Handle any errors that occur during update
            console.error('Error updating category:', error);
            // Optionally, you can show an error message or perform any other action upon failure
        }
    };

    const deleteCategory = async (category: Category) => {
        try {
            // Call the category service to delete the category
            await categoryService.deleteCategory(category.id);
    
            // Once the category is deleted successfully, update the state to remove the deleted category
            setCategoriesState((prevState) => ({
                ...prevState,
                categories: prevState.categories.filter((c) => c.id !== category.id),
                loading: false // Optionally, you can set loading to false here
            }));
    
            // Optionally, you can show a notification or perform any other action upon successful deletion
            console.log('Category deleted successfully');
        } catch (error: unknown) {
            // Check if the error is an AxiosError
            if (error instanceof AxiosError) {
                // Handle any errors that occur during deletion
                handleError(error, true, "No se ha podido eliminar la categoría, puede que todavía tenga productos asociados.");
            } else {
                console.error("An unknown error occurred:", error);
            }
        }
    };

    if (categoriesState.error) {
        return (
            <Paper sx={{ minHeight: "50vh", textAlign: "center", alignContent: "center", backgroundColor: "rgb(255,255,255, 0.8)" }}>
                <Stack alignItems={"center"} spacing={1}>

                    <ErrorOutline fontSize="inherit" style={{ fontSize: "5rem", color: theme.palette.warning.main }} />
                    <Typography variant='h6'>No se han podido cargar las categorías.</Typography>
                </Stack>
            </Paper>)
    }


    return (
        <>
            <Grid container xs={12} md={12} sx={{ minWidth: "40vw" }}>
                <Stack spacing={3} sx={{ width: "100%" }}>
                    <Stack direction={"row"} alignItems={"center"} display={"flex"} justifyContent={"space-around"}>
                        <Typography variant="h5">Categorías</Typography>
                        <Button variant="outlined" onClick={() => { setOpenModal(true) }}>
                            <Stack direction={"row"}>
                                <AddIcon />
                                {!isMobile && <Typography>Añadir Categoría</Typography>}
                            </Stack>

                        </Button>
                    </Stack>
                    <CategoriesTable categories={categoriesState.categories} onCategoryUpdate={updateCategory} onCategoryDelete={deleteCategory} />
                </Stack>
            </Grid>
            <AddCategoryModal open={openModal} onClose={() => { setOpenModal(false) }} onAddCategory={(newCategory) => { addCategory(newCategory) }} />
        </>
    )
}

export default AdminCategoriesPage;
