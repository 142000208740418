import React, { useEffect, useState } from "react";
import { ProductData, ProductPortrait } from "../../../models/product";
import ProductImageList from "../shop/ProductList";

interface FeaturedProductsProps {
    featuredProducts: ProductPortrait[]
}

const FeaturedProducts: React.FC<FeaturedProductsProps> = ({featuredProducts}) => {

    return <ProductImageList products={featuredProducts} fullWidthCols={4}/>;
};

export default FeaturedProducts;