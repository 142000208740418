import { Typography, Grid, Stack, Box, useMediaQuery, useTheme, ImageList, ImageListItem, Dialog, DialogContent } from '@mui/material';
import React from 'react';


function AboutMe() {

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedImage, setSelectedImage] = React.useState<string | null>(null);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (img: string | null) => {
        setSelectedImage(img);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedImage(null);
    };

    const itemData = [
        {
            img: '/images/aboutme/about-me-02.jpg',
            title: 'Dibujos',
        },
        {
            img: '/images/aboutme/about-me-03.jpg',
            title: 'Ninfa',
        },
        {
            img: '/images/aboutme/about-me-01.jpg',
            title: 'Orcas',
        },
        {
            img: '/images/aboutme/about-me-04.jpg',
            title: 'Naturaleza',
        },
    ];

    return (
        <Box sx={{
            p: 4,
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            overflow: 'hidden',
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Subtle shadow
            borderRadius: "8px",  // Rounded corners
            border: "2px solid rgba(0, 0, 0, 0.05)",  // Optional subtle border
        }}>
            {/* Title */}
            <Typography variant="h3" component="h1" align="center" gutterBottom>
                ¡Hola! Soy Tania
            </Typography>

            {/* Circular Image */}
            <Grid container justifyContent="center" sx={{ marginBottom: 4 }}>
                <Box
                    component="img"
                    src="/images/aboutme/Tania.png"
                    alt="Local"
                    sx={{
                        width: isMobile ? '300px' : '400px',
                        height: isMobile ? '300px' : '400px',
                        borderRadius: '50%',       // Make the image round
                        objectFit: 'cover',        // Ensure the image fits properly
                        border: '3px solid #ddd',  // Optional: Add a border for styling
                    }}
                />
            </Grid>

            {/* About Me Text */}
            <Stack spacing={2}>
                <Typography variant="body1" align="justify">
                    Para que me conozcas un poquito he estado pensando una palabra que diga mucho de mí, y ninguna se ajusta tanto como “curiosa”. Me gusta tanto descubrir y descubrirme que, en no demasiados años de vida, he hecho múltiples cosas muy diversas.
                </Typography>
                <Typography variant="body1" align="justify">
                    Mis mayores aficiones y pasiones nacen de mis entrañas y siempre están muy ligadas a la creatividad y a la naturaleza, mis dos fuentes de vida que intento cuidar.
                </Typography>
                <Typography variant="body1" align="justify">
                    Este pequeño proyecto empezó siendo un camino más hacia mi interior, hacia mi propia escucha para encontrar paz creando. Un camino algo más evolucionado de lo que llevo haciendo desde niña, con dibujos, diseños, acuarelas…
                </Typography>
                <Typography variant="body1" align="justify">
                    Me enamoré del arte de trabajar metales preciosos, la orfebrería, y supe en todo momento que las gemas naturales me acompañarían en casi cada obra, como el agua está presente en toda forma de vida.
                </Typography>
                <Typography variant="body1" align="justify">
                    De pequeña, mi abuelo me ayudó a descubrir la puerta a mi mayor fascinación, y he pasado gran parte de mi tiempo estudiando y aprendiendo sobre los animales y el mundo marino. De ahí salieron las Náyades, ninfas de agua dulce y nietas del océano, encargadas de proteger los lugares que habitan. Y de ahí salió el nombre de este bonito proyecto de vida.
                </Typography>
            </Stack>

            {/* Images */}
            <ImageList sx={{ width: "100%" }} cols={isMobile ? 2 : 4} rowHeight={"auto"} gap={10}>
                {itemData.map((item) => (
                    <ImageListItem key={item.img} onClick={() => handleClickOpen(item.img)}>
                        <img
                            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                            alt={item.title}
                            loading="lazy"
                            style={{ cursor: 'pointer', "borderRadius": "16px" }}
                        />
                    </ImageListItem>
                ))}
            </ImageList>

            <Dialog open={open} onClose={handleClose} maxWidth="md">
                <DialogContent>
                    {selectedImage && (
                        <img
                            src={`${selectedImage}?w=800&h=600&fit=crop&auto=format`}
                            alt="Expanded"
                            style={{ width: '100%', height: 'auto' }}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </Box>

    );
}

export default AboutMe;
