import { Modal, Typography, Button, IconButton, Box, Stack, useTheme, useMediaQuery } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';

interface InfoModalProps {
    open: boolean;
    onClose: () => void;
    title: React.ReactNode;
    content: React.ReactNode;
}

const InfoModal: React.FC<InfoModalProps> = ({
    open,
    onClose,
    title,
    content,
}) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="info-modal-title"
            aria-describedby="info-modal-description"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box sx={{
                position: 'relative',  // Positioning for the close button
                bgcolor: 'background.paper',
                boxShadow: 25,
                p: 4,
                borderRadius: '12px',
                maxWidth: isMobile ? '400px': '600px',
                width: '100%',
            }}>
                {/* Close Button */}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        color: theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                
                <Stack spacing={3}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <InfoIcon sx={{ color: theme.palette.info.main, fontSize: 40 }} />
                        <Typography variant="h5" fontWeight="bold" color="textPrimary">
                            {title}
                        </Typography>
                    </Stack>
                    <Typography variant="body1" color="textSecondary">
                        {content}
                    </Typography>
                    <Button 
                        variant="contained" 
                        onClick={onClose} 
                        sx={{
                            alignSelf: 'center',
                            bgcolor: theme.palette.info.main,
                            color: 'white',
                            '&:hover': {
                                bgcolor: theme.palette.info.dark,
                            },
                        }}
                    >
                        Entendido
                    </Button>
                </Stack>
            </Box>
        </Modal>
    );
};

export default InfoModal;
