import { AxiosInstance } from "axios";
import { ApiConfig } from "./apiConfig";
import { OrderInput, OrderOutput, OrderOutputFull } from "../models/order";
import { ProductData, ProductPortrait } from "../models/product";

class FeaturedProductService {
    axiosInstance: AxiosInstance;

    constructor(apiConfig: ApiConfig) {
        this.axiosInstance = apiConfig.axiosInstance;
    }

    async addToFeatured(productId: number): Promise<void> {
        try {
            let url = '/featured_products';
            if (productId) {
                url += `?product_id=${productId}`;
            }

            const response = await this.axiosInstance.post(url);

            return response.data;
        } catch (error) {
            console.error('Error while adding product to featured products:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

    async removeFromFeatured(productId: number): Promise<void> {
        try {
            let url = '/featured_products';

            if (productId) {
                url += `?product_id=${productId}`;
            }

            const response = await this.axiosInstance.delete(url);

            return response.data;
        } catch (error) {
            console.error('Error validating order:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

    async getAllFeaturedProducts(): Promise<ProductData[]> {
        try {
            const url = '/featured_products';
            const response = await this.axiosInstance.get<ProductData[]>(url);

            return response.data as ProductData[];

        } catch (error) {
            console.error('Error validating order:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

    async getAllFeaturedProductsPortraits(): Promise<ProductPortrait[]> {
        try {
            const url = '/featured_products_portraits';
            const response = await this.axiosInstance.get<ProductPortrait[]>(url);

            return response.data;

        } catch (error) {
            console.error('Error validating order:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }
}

export default FeaturedProductService;