import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import React, { useState, useEffect } from 'react';
import theme from '../../../style/theme';
import { CarouselImageOutput } from '../../../models/carousel';

interface ImageCarouselProps {
  images: CarouselImageOutput[];
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Auto-rotate images every 10 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 10000); // 10 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [images.length]);

  return (
    <Box
      sx={{
        margin: 'auto',
        overflow: 'hidden',
        position: 'relative',
        maxWidth: '100%',
      }}
    >
      <IconButton
        sx={{
          position: 'absolute',
          top: '50%',
          left: 0,
          transform: 'translateY(-50%)',
          zIndex: 1,
          color: theme.palette.primary.contrastText,
        }}
        onClick={handlePrevClick}
      >
        <ArrowBack />
      </IconButton>
      <Box
        component="img"
        src={`${images[currentImageIndex]?.data}`}
        alt={`Image ${currentImageIndex + 1}`}
        sx={{
          width: '100%',
          height: 'auto', // Automatically adjust height based on aspect ratio
          maxHeight: isMobile ? 300 : 900, // Optional maxHeight for mobile and desktop
          aspectRatio: isMobile ? '3/2' : '3/2', // Set aspect ratio based on mobile or desktop
          transition: 'transform 0.5s ease',
        }}
      />
      <IconButton
        sx={{
          position: 'absolute',
          top: '50%',
          right: 0,
          transform: 'translateY(-50%)',
          zIndex: 1,
          color: theme.palette.primary.contrastText,
        }}
        onClick={handleNextClick}
      >
        <ArrowForward />
      </IconButton>

      {/* Bullets for image indicators */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          bottom: 16,
          left: '50%',
          transform: 'translateX(-50%)',
          gap: 1,
        }}
      >
        {images.map((_, index) => (
          <Box
            key={index}
            onClick={() => setCurrentImageIndex(index)}
            sx={{
              width: 10,
              height: 10,
              borderRadius: '50%',
              backgroundColor:
                index === currentImageIndex
                  ? theme.palette.primary.main
                  : "white",
              cursor: 'pointer',
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ImageCarousel;
