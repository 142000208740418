import React, { useState, ChangeEvent, FormEvent } from 'react';
import { TextField, Button, Grid, Paper } from '@mui/material';
import { UserInput, UserOutput } from '../../../../models/user';

interface UserFormProps {
    isUpdate: boolean;
    user: UserOutput | null;
    onSave: (userInput: UserInput) => void;
    onCancel: () => void;
}

const UserForm: React.FC<UserFormProps> = ({ isUpdate, user, onSave, onCancel }) => {
    const [formData, setFormData] = useState<UserInput>({
        name: user?.name || '',
        last_name: user?.last_name || '',
        email: user?.email || '',
        username: user?.username || '',
        password: null, 
        active: user?.active || true,
    });

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        onSave(formData);
    };

    return (
        <Paper elevation={3} sx={{ p: 4 }}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Nombre"
                            fullWidth
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Apellido"
                            fullWidth
                            name="last_name"
                            value={formData.last_name}
                            onChange={handleInputChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Correo Electrónico"
                            fullWidth
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Nombre de Usuario"
                            fullWidth
                            name="username"
                            value={formData.username}
                            onChange={handleInputChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Contraseña"
                            fullWidth
                            name="password"
                            type="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            required={!isUpdate}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="outlined" color="primary" type="submit">
                            {isUpdate ? 'Actualizar' : 'Registrar'}
                        </Button>
                        <Button variant="outlined" color="error" onClick={onCancel} sx={{ ml: 2 }}>
                            Cancelar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    );
};

export default UserForm;