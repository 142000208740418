import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Stack, Typography, CircularProgress, IconButton, Paper, useTheme } from '@mui/material';
import { useShoppingCartContext } from '../../context/cartContext';
import { ShipmentType } from '../../../models/shipmentType';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';

import { CustomerDataForm, OrderInput, OrderOutput } from '../../../models/order';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OrderService from '../../../api/orderService';
import apiConfigInstance from '../../../api/apiConfig';
import PaymentService from '../../../api/paymentService';

import FailureModal from '../../utils/modals/FailureModal';
import { decryptData, encryptData, getEncryptionKey } from '../../../utils/encrypt';
import PaymentIcon from '@mui/icons-material/Payment';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';

import { useError } from '../../errors/ErrorContext'; // Import the useError hook
import PaymentSummary from '../payment/PaymentSummary';
import OrderValidationDialog from '../payment/validateOrderModal';
import CardPaymentForm from '../payment/CardPaymentForm';
import CustomerDataFormComponent from '../payment/CustomerDataForm';
import { PaymentStatus } from '../payment/paymentStatus';
import { AxiosError } from 'axios';
import CreditCardIcon from '@mui/icons-material/CreditCard';

const PaymentPage: React.FC = () => {
    const { removeItemsFromCart } = useShoppingCartContext()

    const orderService = new OrderService(apiConfigInstance);
    const paymentService = new PaymentService(apiConfigInstance);
    const { handleError } = useError();  // Use the error handling context

    const [customerData, setCustomerData] = useState<{
        submited: boolean,
        data: CustomerDataForm | null
    }>({
        submited: false,
        data: null
    });
    const { products, getShipment, computePrice } = useShoppingCartContext();
    const [shipment, setShipment] = useState<ShipmentType>();
    const [isLoading, setIsLoading] = useState(true);
    const [stripe, setStripe] = useState<{ loading: boolean, stripeObject: Stripe | null }>({ loading: true, stripeObject: null });
    const [isValidating, setIsValidating] = useState(false);
    const [failureData, setFailureData] = useState<{ open: boolean, title: string, text: string, onClose?: () => void }>({ open: false, title: "", text: "" });
    const [processingPayment, setProcessingPayment] = useState<boolean>(false);
    const [processingOrder, setProcessingOrder] = useState<boolean>(false);
    const [checkingPaymentStatus, setCheckingPaymentStatus] = useState<boolean>(false);


    const theme = useTheme();
    const navigate = useNavigate();

    useEffect(() => {
        const pubKey = process.env.REACT_APP_STRIPE_PUB_KEY;

        loadStripe(pubKey!).then((stripe) => {
            setStripe({ loading: false, stripeObject: stripe });
        }).catch(error => {
            console.error("Error al cargar Stripe")
            throw error as Error;
        }
        );
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {

                if (products.loading) {
                    setIsLoading(true);
                } else {
                    if (products.error || products.products.length === 0) {
                        console.log(products)
                        throw new Error("No se han podido cargar los productos.")
                    }
                }


                const storedShipment = await getShipment();

                if (storedShipment === null) {
                    throw new Error("No se ha podido cargar el tipo de envío.")
                }

                setShipment(storedShipment);
                setIsLoading(false);

            } catch (error) {
                console.log(error)
                handleError(error as Error, true, "Error al cargar los datos del carrito, por favor intentelo de nuevo.", () => navigate("/cart"));
            }
        };

        fetchData();
    }, [products]);

    useEffect(() => {
        const checkOrderPaymentStatus = async () => {
            try {
                const encryptedOrder = localStorage.getItem('currentOrder');
                if (encryptedOrder) {
                    setCheckingPaymentStatus(true);
                    const order: OrderOutput = decryptData(encryptedOrder, getEncryptionKey());
                    const orderData = await orderService.getOrder(order.id);
                    console.log(orderData)
                    setCheckingPaymentStatus(false);
                    if (orderData.payments.length > 0) {
                        const lastPaymentStatus = orderData.payments[orderData.payments.length - 1].status;
                        if (lastPaymentStatus === PaymentStatus.Processing) {
                            setProcessingOrder(true);
                        } else if (lastPaymentStatus === PaymentStatus.Succeeded) {
                            navigate('/success');
                        } else {
                            setCustomerData({
                                submited: false,
                                data: {
                                    name: order.name,
                                    last_name: order.last_name,
                                    mail: order.mail,
                                    phone: order.phone,
                                    address_line_1: order.address_line_1,
                                    address_line_2: order.address_line_2,
                                    province: order.province,
                                    postal_code: order.postal_code,
                                    city: order.city
                                }
                            });
                        }
                    }
                    else {
                        setCustomerData({
                            submited: false,
                            data: {
                                name: order.name,
                                last_name: order.last_name,
                                mail: order.mail,
                                phone: order.phone,
                                address_line_1: order.address_line_1,
                                address_line_2: order.address_line_2,
                                province: order.province,
                                postal_code: order.postal_code,
                                city: order.city
                            }
                        });
                    }
                }
            } catch (error) {
                console.log(error)
                setCheckingPaymentStatus(false);
                if (error instanceof AxiosError) {
                    if (error.response?.status === 404) {
                        localStorage.removeItem('currentOrder');
                        // Handle additional actions for 404, e.g., show a message or redirect the user
                        return;
                    } else {
                        handleError(error as Error, true, "Error al verificar el estado del pago, por favor intentelo de nuevo.", () => checkOrderPaymentStatus());
                    }
                } else {
                    handleError(error as Error, true, "Error al verificar el estado del pago, por favor intentelo de nuevo.", () => checkOrderPaymentStatus());
                }
                
                
            }
        };

        checkOrderPaymentStatus();
    }, [navigate]);

    if (checkingPaymentStatus) {
        return (
            <Stack spacing={4} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <CircularProgress />
                <Typography variant="h6">Verificando estado de pago...</Typography>
            </Stack>
        );

    } else {
        if (isLoading) {
            return (
                <Stack spacing={4} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <CircularProgress />
                    <Typography variant="h6">Cargando carrito...</Typography>
                </Stack>
            );
        }
    }



    if (processingOrder) {
        return (
            <Paper sx={{ minWidth: "80%", p: 4, mx: 'auto', boxShadow: 4, textAlign: 'center', minHeight: "50vh", opacity: 0.8, alignContent: "center" }}>
                <Stack spacing={2} alignItems="center">
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <PaymentIcon color="primary" fontSize="large" />
                        <Typography variant="h6">El pago está procesándose...</Typography>
                    </Stack>
                    <CircularProgress color="primary" size={50} thickness={4} />
                </Stack>
            </Paper>
        );
    }

    const handleGoBack = () => {
        setCustomerData((prevData) => ({
            ...prevData,
            submited: false
        }));
    };

    const saveOrderToLocalStorage = (order: OrderOutput) => {
        try {
            const encryptedOrder = encryptData(order, getEncryptionKey());
            localStorage.setItem("currentOrder", encryptedOrder);
        } catch (error) {
            console.error("Error al guardar el pedido en el almacenamiento local. Error: " + error);
            throw error as Error;
        }
    };

    const startPaymentFlow = async (
        customerData: CustomerDataForm,
        shipmentId: string | number,
        productsId: string[],
        currency: string,
        amount: number
    ) => {
        setProcessingPayment(true);

        try {
            const orderInput: OrderInput = {
                name: customerData.name,
                last_name: customerData.last_name,
                mail: customerData.mail,
                phone: customerData.phone,
                address_line_1: customerData.address_line_1,
                address_line_2: customerData.address_line_2,
                shipment_method_id: shipmentId,
                product_ids: productsId,
                currency: currency,
                amount: amount,
                city: customerData.city,
                province: customerData.province,
                postal_code: customerData.postal_code,
            };

            try {
                const currentOrder = localStorage.getItem('currentOrder');

                let registeredOrder: OrderOutput | null = null;

                if (currentOrder !== null) {
                    const orderDecrypted: OrderOutput = decryptData(currentOrder, getEncryptionKey());
                    registeredOrder = await orderService.checkExistingOrder(orderDecrypted.id, orderInput);
                } else {
                    registeredOrder = await orderService.registerOrder(orderInput);
                }

                if (registeredOrder) {
                    const { sessionId } = await paymentService.checkout(registeredOrder.id);
                    saveOrderToLocalStorage(registeredOrder);
                    stripe.stripeObject?.redirectToCheckout({ sessionId });
                } else {
                    throw new Error("Error al registrar o recuperar el pedido.");
                }

            } catch (error) {
                console.log(error)
                handleError(error as Error | AxiosError, true, "Fallo en el proceso de pago");
            }

        } catch (error) {
            console.log(error)
            handleError(error as Error | AxiosError, true, "Fallo al iniciar el pago");
        } finally {
            setProcessingPayment(false);
        }
    };

    const validateOrder = (customerData: CustomerDataForm, shipmentId: string | number, productsId: string[], currency: string, amount: number) => {
        setIsValidating(true);
        const orderInput: OrderInput = {
            name: customerData.name,
            last_name: customerData.last_name,
            mail: customerData.mail,
            phone: customerData.phone,
            address_line_1: customerData.address_line_1,
            address_line_2: customerData.address_line_2,
            shipment_method_id: shipmentId,
            product_ids: productsId,
            currency: currency,
            amount: amount,
            city: customerData.city,
            province: customerData.province,
            postal_code: customerData.postal_code
        };

        orderService.validateOrder(orderInput).then((validationResult) => {
            if (validationResult.status) {
                setCustomerData({
                    submited: true,
                    data: customerData
                });
            } else {
                const toRemove: number[] = [];

                // Add not available products to the removal list
                if (validationResult.not_available_products.length > 0) {
                    for (const productId of validationResult.not_available_products) {
                        toRemove.push(productId);
                    }
                }

                // Add not found products to the removal list
                if (validationResult.not_found_products.length > 0) {
                    for (const productId of validationResult.not_found_products) {
                        toRemove.push(productId);
                    }
                }

                // Remove items from cart if there are any to remove
                if (toRemove.length > 0) {
                    removeItemsFromCart(toRemove);
                }

                setFailureData({
                    open: true, title: "Orden no válida.", text: "", onClose: () => {
                        navigate("/cart")
                        setFailureData({ open: false, title: "", text: "", onClose: undefined })
                    }
                })
            }


        }).catch((error) => {
            handleError(error, true, "No se ha podido validar la orden, por favor intentalo de nuevo.");
        }).finally(() => {
            setIsValidating(false);
        });
    };

    return (
        <Stack spacing={4} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            {
                !customerData.submited && (
                    <>
                        <Stack spacing={1} direction={"row"} alignItems={"center"}>
                            <IconButton onClick={() => navigate("/cart")}><ArrowBackIosIcon /></IconButton>
                            <ContactMailIcon fontSize='large' sx={{ color: theme.palette.primary.main }} />
                            <Typography variant='h5' fontWeight={"bold"}>Datos del comprador</Typography>
                        </Stack>

                        <CustomerDataFormComponent initialvalue={customerData.data} onSubmit={(formData) => {
                            validateOrder(formData, shipment!.id, products.products.map((product) => product.id), "eur", computePrice() + shipment!.price);
                        }} />
                    </>
                )
            }
            {
                processingPayment &&
                <Paper sx={{ minWidth: "80%", p: 4, mx: 'auto', boxShadow: 4, textAlign: 'center' }}>
                    <Stack spacing={2} alignItems="center">
                        <Stack direction={"row"} alignItems={"center"} spacing={1}>
                            <PaymentIcon color="primary" fontSize="large" />
                            <Typography variant="h6">Procesando pago...</Typography>
                        </Stack>

                        <CircularProgress color="primary" size={50} thickness={4} />
                    </Stack>
                </Paper>
            }
            {
                !processingPayment && customerData.submited && (
                    <>
                        <Stack spacing={1} direction={"row"} alignItems={"center"}>
                            <IconButton onClick={handleGoBack}><ArrowBackIosIcon /></IconButton>
                            <Stack alignItems={"center"} spacing={1} direction={"row"}>
                                <CreditCardIcon fontSize='large' sx={{ color: theme.palette.primary.main }} />
                                <Typography fontWeight={"bold"} variant='h5'>Hacer pago</Typography>
                            </Stack>

                        </Stack>

                        {
                            stripe.loading ? (
                                <CircularProgress />
                            ) : (
                                <Elements stripe={stripe.stripeObject}>
                                    <CardPaymentForm onCardValidation={(paymentMethod) => {
                                        startPaymentFlow(customerData.data!, shipment!.id, products.products.map((product) => product.id), "eur", computePrice() + shipment!.price);
                                    }} />
                                </Elements>
                            )
                        }
                    </>
                )
            }
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <EuroSymbolIcon fontSize='large' sx={{ color: theme.palette.primary.main }} />
                <Typography variant='h5' fontWeight={"bold"}>Costes Totales</Typography>
            </Stack>

            <PaymentSummary shipment={shipment!} amount={computePrice()} />
            <OrderValidationDialog open={isValidating} />
            <FailureModal open={failureData.open} onClose={() => {
                if (failureData.onClose) {
                    failureData.onClose()
                } else {
                    setFailureData({ open: false, title: "", text: "", onClose: undefined })
                }

            }} title={<Typography>{failureData.title}</Typography>} content={<Typography>{failureData.text}</Typography>} />
        </Stack>
    );
};

export default PaymentPage;
