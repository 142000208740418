import React, { useState } from 'react';
import { Container, Button, Typography, Paper, Stack, useTheme, CircularProgress, useMediaQuery } from '@mui/material';
import UsersTable from './UsersTable';
import UserForm from './UserForm';
import { UserInput, UserOutput } from '../../../../models/user';
import { useAdminContext } from '../AdminContext';
import { useError } from '../../../errors/ErrorContext';
import {  AxiosError } from 'axios';
import { ErrorOutline } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';

const UsersPage: React.FC = () => {
    const { usersState, addUser, updateUser, deleteUser } = useAdminContext();
    const { handleError } = useError()

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [currentUser, setCurrentUser] = useState<UserOutput | null>(null);

    const handleAddUser = () => {
        setCurrentUser(null); // Clear any existing user data
        setIsEditing(true);   // Switch to form view for adding a new user
    };

    const handleEditUser = (user: UserOutput) => {
        setCurrentUser(user); // Set the user data for editing
        setIsEditing(true);   // Switch to form view for editing
    };

    const handleSaveUser = async (userInput: UserInput) => {
        try {
            if (currentUser) {
                // Update existing user
                await updateUser(currentUser.id, userInput);
            } else {
                // Create new user
                await addUser(userInput);
            }
            setIsEditing(false);  // Switch back to table view
        } catch (error) {
            handleError(error as Error | AxiosError, true, currentUser ? "No se ha podido actualizar el usario." : "No se ha podido crear el usuario.");
        }
    };

    const handleDeleteUser = async (id: number) => {
        try {
            await deleteUser(id);
        } catch (error) {
            handleError(error as Error | AxiosError, true, "No se ha podido eliminar el usario.");
        }
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setCurrentUser(null);
    };

    if (usersState.loading) {
        return (<Paper sx={{ minHeight: "50vh", textAlign: "center", alignContent: "center", backgroundColor: "rgb(255,255,255, 0.8)" }}>
            <Stack alignItems={"center"} spacing={1}>
                <CircularProgress />
                <Typography variant='h6'>Cargando usuarios...</Typography>
            </Stack>
        </Paper>)
    }

    if (usersState.error) {
        return (<Paper sx={{ minHeight: "50vh", textAlign: "center", alignContent: "center", backgroundColor: "rgb(255,255,255, 0.8)" }}>
            <Stack alignItems={"center"} spacing={1}>
                <ErrorOutline fontSize="inherit" style={{ fontSize: "5rem", color: theme.palette.warning.main }} />
                <Typography variant='h6'>No se han podido cargar los usuarios.</Typography>
            </Stack>
        </Paper>)
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, p: 2, pb: 4, backgroundColor: "rgb(255,255,255,0.9)" }}>
            <Typography variant={isMobile ? "h5" : "h4"} sx={{mb: isMobile ? 2: 1}} textAlign="center">
                Gestión de Usuarios
            </Typography>

            {isEditing ? (
                <UserForm
                    isUpdate={!!currentUser}
                    user={currentUser}
                    onSave={handleSaveUser}
                    onCancel={handleCancelEdit}
                />
            ) : (
                <Stack>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleAddUser}
                        sx={{ mb: 2, backgroundColor: "white", width: isMobile ? 125 : 250,  alignSelf: "flex-end" }}
                    >
                        <Stack direction={"row"} alignItems={"center"} spacing={1}>
                            <AddIcon />
                            <Typography>Nuevo</Typography>
                        </Stack>
                        
                    </Button>
                    <UsersTable
                        users={usersState.users}
                        onEdit={handleEditUser}
                        onDelete={handleDeleteUser}
                    />
                </Stack>
            )}
        </Container>
    );
};

export default UsersPage;