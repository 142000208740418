import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import { CartItem } from '../../models/shopingCart';
import ProductService from '../../api/productService';
import apiConfigInstance from '../../api/apiConfig';
import { ProductData } from '../../models/product';
import { ShipmentType } from '../../models/shipmentType';
import ShipmentTypeService from '../../api/shipmentTypeService';
import { decryptData, getEncryptionKey, encryptData } from '../../utils/encrypt';
import { Axios, AxiosError } from 'axios';
import { error } from 'console';

interface ProductsState {
    loading: boolean;
    products: CartItem[];
    error: AxiosError | Error | null;
}

interface ShipmentTypesState {
    loading: boolean;
    shipmentTypes: ShipmentType[];
    error: AxiosError | null;
}

interface ShoppingCart {
    products: ProductsState;
    shipments: ShipmentTypesState;
    addToCart: (product: ProductData) => void;
    checkProductInCart: (productId: string) => boolean;
    countProducts: () => number;
    computePrice: () => number;
    removeFromCart: (productId: string | number) => void;
    removeItemsFromCart: (productIds: string[] | number[]) => void;
    clearCart: () => void;
    clearCartItems: (productIds: number[]) => void;
    setShipment: (shipmentType: ShipmentType) => void;
    getShipment: () => Promise<ShipmentType | null>;
}

export const ShoppingCartContext = createContext<ShoppingCart>({
    products: { loading: false, products: [], error: null },
    shipments: { loading: false, shipmentTypes: [], error: null },
    addToCart: (product: ProductData) => { },
    checkProductInCart: (productId: string) => false,
    countProducts: () => 0,
    computePrice: () => 0.0,
    removeFromCart: (productId: string | number) => { },
    removeItemsFromCart: (productIds: string[] | number[]) => { },
    clearCart: () => { },
    clearCartItems: (productIds: number[]) => { },
    setShipment: (shipmentType: ShipmentType) => { },
    getShipment: async () => null,
});

export const ShoppingCartProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [products, setProducts] = useState<ProductsState>({ loading: true, products: [], error: null });
    const [shipments, setShipmentTypes] = useState<ShipmentTypesState>({ loading: true, shipmentTypes: [], error: null });
    const productService = new ProductService(apiConfigInstance);
    const shipmentTypeService = new ShipmentTypeService(apiConfigInstance);

    // Load cart from local storage on mount
    useEffect(() => {
        const storedCart = localStorage.getItem('cart');
        if (storedCart) {
            try {
                const decryptedCart = decryptData(storedCart, getEncryptionKey());
                const cartItemIds: string[] = JSON.parse(decryptedCart);
                const loadProducts = async () => {
                    let cartItems: CartItem[] = [];
                    let updatedCartItemIds: string[] = [...cartItemIds];
                    let hasError = false; // To track if any non-404 error occurred
    
                    // Use for...of loop to handle each product individually
                    for (const productId of cartItemIds) {
                        try {
                            console.log(productId)
                            const product = await productService.getProduct(productId);

                            if (product.sold) {
                                // Remove sold items from the cart
                                updatedCartItemIds = updatedCartItemIds.filter(cartItemId => cartItemId !== productId);
                            } else {
                                cartItems.push({
                                    id: product.id,
                                    name: product.name,
                                    short_description: product.short_description,
                                    main_image: product.main_image,
                                    price: product.price
                                });
                            }
    
                        } catch (error) {
                            console.log(error)
                            const axiosError = error as AxiosError;
                            if (axiosError.response?.status === 404) {
                                // Remove non-existent items from the cart
                                updatedCartItemIds = updatedCartItemIds.filter(cartItemId => cartItemId !== productId);
                            } else {
                                // Mark that an error occurred, but continue processing
                                console.error(`Error fetching product with ID ${productId}:`, axiosError.message);
                                updatedCartItemIds = updatedCartItemIds.filter(cartItemId => cartItemId !== productId);
                            }
                        }
                    }
    
                    // Update localStorage once with the filtered cart items
                    const encryptedCart = encryptData(JSON.stringify(updatedCartItemIds), getEncryptionKey());
                    localStorage.setItem('cart', encryptedCart);
    
                    // Set the state with the loaded products and handle error if it occurred
                    setProducts({
                        loading: false,
                        products: cartItems,
                        error: hasError ? new Error('Some products failed to load. Check the console for details.') : null,
                    });
                };
                loadProducts();
            } catch (error) {
                const axiosError = error as AxiosError;
                console.error('Error decrypting cart data:', axiosError);
                setProducts({ loading: false, products: [], error: axiosError });
            }
        } else {
            setProducts({ loading: false, products: [], error: null });
        }
    }, []);
    
    // Load shipment types on mount
    useEffect(() => {
        const fetchShipmentTypes = async () => {
            try {
                const shipments = await shipmentTypeService.getShipmentTypes();
                setShipmentTypes({ loading: false, shipmentTypes: shipments, error: null });
            } catch (error) {
                const axiosError = error as AxiosError;
                console.error("Error fetching shipment types:", axiosError);
                setShipmentTypes({ loading: false, shipmentTypes: [], error: axiosError });
            }
        };

        fetchShipmentTypes();
    }, []);

    const addToCart = (product: ProductData) => {
        const newProduct = {
            id: product.id,
            name: product.name,
            short_description: product.short_description,
            main_image: product.main_image,
            price: product.price
        };

        setProducts(prevState => ({
            ...prevState,
            products: [...prevState.products, newProduct],
            error: null,
        }));

        const storedCart = localStorage.getItem('cart');
        let updatedCart: string[];
        if (storedCart) {
            const decryptedCart = decryptData(storedCart, getEncryptionKey());
            updatedCart = [...JSON.parse(decryptedCart), newProduct.id];
        } else {
            updatedCart = [newProduct.id];
        }
        const encryptedCart = encryptData(JSON.stringify(updatedCart), getEncryptionKey());
        localStorage.setItem('cart', encryptedCart);
    };

    const removeItemsFromCart = (productIds: (string | number)[]) => {
        // Convert productIds to a Set of strings for consistent comparison
        const productIdsSet = new Set(productIds.map(id => id.toString()));
    
        setProducts(prevState => ({
            ...prevState,
            products: prevState.products.filter(item => !productIdsSet.has(item.id.toString())),
            error: null,
        }));
    
        const storedCart = localStorage.getItem('cart');
        if (storedCart) {
            const decryptedCart = decryptData(storedCart, getEncryptionKey());
            const cartItemIds: string[] = JSON.parse(decryptedCart);
    
            // Filter the cart items using the same Set of string IDs
            const updatedCart = cartItemIds.filter(id => !productIdsSet.has(id));
    
            const encryptedCart = encryptData(JSON.stringify(updatedCart), getEncryptionKey());
            localStorage.setItem('cart', encryptedCart);
        }
    };

    const removeFromCart = (productId: string | number) => {
        setProducts(prevState => ({
            ...prevState,
            products: prevState.products.filter(item => item.id !== productId),
            error: null,
        }));

        const storedCart = localStorage.getItem('cart');
        if (storedCart) {
            const decryptedCart = decryptData(storedCart, getEncryptionKey());
            const cartItemIds: string[] = JSON.parse(decryptedCart);
            const updatedCart = cartItemIds.filter(id => id.toString() !== productId.toString());
            const encryptedCart = encryptData(JSON.stringify(updatedCart), getEncryptionKey());
            localStorage.setItem('cart', encryptedCart);
        }
    };

    const checkProductInCart = (productId: string | number): boolean => {
        return products.products.some(product => product.id === productId);
    };

    const countProducts = (): number => {
        return products.products.length;
    };

    const computePrice = (): number => {
        return products.products.reduce((total, product) => total + product.price, 0);
    };

    const setShipment = (shipmentType: ShipmentType) => {
        localStorage.setItem('shipment', encryptData(JSON.stringify(shipmentType.id), getEncryptionKey()));
    };

    const getShipment = () => {
        return new Promise<ShipmentType | null>((resolve, reject) => {
            const storedShipment = localStorage.getItem('shipment');
            if (storedShipment) {
                shipmentTypeService.getShipmentType(Number(decryptData(storedShipment, getEncryptionKey())))
                    .then((shipment) => {
                        resolve(shipment);
                    })
                    .catch((error) => {
                        const axiosError = error as AxiosError;
                        console.error('Error fetching shipment:', axiosError);
                        reject(null);
                    });
            } else {
                resolve(null);
            }
        });
    };

    const clearCart = () => {
        setProducts({ loading: false, products: [], error: null });
        localStorage.removeItem('cart');
    };

    const clearCartItems = (itemIds: number[]) => {
        const storedCart = localStorage.getItem('cart');
        console.log("StoredCart: " + storedCart);
        console.log(itemIds)
        if (storedCart) {
            
            const decryptedCart = decryptData(storedCart, getEncryptionKey());
            console.log("Decrypted Cart: ", decryptedCart);
    
            let cartItemIds: string[] = [];
    
            // Check if decryptedCart is a string and needs parsing
            if (typeof decryptedCart === 'string') {
                try {
                    cartItemIds = JSON.parse(decryptedCart);
                } catch (error) {
                    console.error("Failed to parse decryptedCart as JSON", error);
                    return;
                }
            } else if (Array.isArray(decryptedCart)) {
                cartItemIds = decryptedCart;
            } else {
                console.error("Decrypted cart is neither a string nor an array");
                return;
            }
    
            console.log("Cart Item IDs: ", cartItemIds);
    
            // Filter out the cart items that don't match the provided itemIds
            const updatedCart = cartItemIds.filter(id => !itemIds.includes(Number(id)));
            
            const encryptedCart = encryptData(JSON.stringify(updatedCart), getEncryptionKey());
            localStorage.setItem('cart', encryptedCart);
    
            console.log("Updated Cart after removal: ", updatedCart);
        }
    };
    

    return (
        <ShoppingCartContext.Provider value={{
            products,
            shipments,
            addToCart,
            checkProductInCart,
            countProducts,
            computePrice,
            removeFromCart,
            removeItemsFromCart,
            clearCart,
            clearCartItems,
            setShipment,
            getShipment
        }}>
            {children}
        </ShoppingCartContext.Provider>
    );
};

export const useShoppingCartContext = () => {
    const context = useContext(ShoppingCartContext);
    if (!context) {
        throw new Error('useShoppingCartContext must be used within a ShoppingCartProvider');
    }
    return context;
};
