import { AxiosInstance } from "axios";
import { ApiConfig } from "./apiConfig";
import { OrderInput, OrderOutput, OrderOutputFull, OrderPayment, OrderValidation } from "../models/order";

class OrderService {
    axiosInstance: AxiosInstance;

    constructor(apiConfig: ApiConfig) {
        this.axiosInstance = apiConfig.axiosInstance;
    }

    async registerOrder(orderInput: OrderInput): Promise<OrderOutput> {
        try {
            let url = '/orders';

            const response = await this.axiosInstance.post<OrderOutput>(url, orderInput);
            return response.data;
        } catch (error) {
            console.error('Error registering order:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

    async checkExistingOrder(order_id: Number, orderInput: OrderInput): Promise<OrderOutput> {
        try {
            let url = `/orders/check_existing_order?order_id=${order_id}`;

            const response = await this.axiosInstance.post<OrderOutput>(url, orderInput);
            return response.data;
        } catch (error) {
            console.error('Error registering order:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

    async validateOrder(orderInput: OrderInput): Promise<OrderValidation> {
        try {
            const url = '/orders/validate_order';
            const response = await this.axiosInstance.post<OrderValidation>(url, orderInput);
            return response.data;
        } catch (error) {
            console.error('Error validating order:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

    async getOrder(orderId: Number): Promise<OrderOutput> {
        try {
            const url = `/order?order_id=${orderId}`;

            const response = await this.axiosInstance.get<OrderOutput>(url);

            return response.data as OrderOutput;

        } catch (error) {
            console.error('Error retrieving orders:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

    async getOrderPayments(orderId: Number): Promise<OrderPayment[]> {
        try {
            const url = `/order/payments?order_id=${orderId}`;

            const response = await this.axiosInstance.get<OrderPayment[]>(url);

            return response.data as OrderPayment[];

        } catch (error) {
            console.error('Error retrieving orders:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

    async getOrdersFull({ startDate = "", endDate = "", status = undefined }: { startDate?: string, endDate?: string, status?: string } = {}): Promise<OrderOutputFull[]> {
        try {
            const url = '/orders/full';
            const params: Record<string, string> = {};

            if (startDate) params.start_date = startDate;
            if (endDate) params.end_date = endDate;
            if (status) params.status = status;

            const response = await this.axiosInstance.get<OrderOutputFull[]>(url, { params });
            return response.data;
        } catch (error) {
            console.error('Error retrieving orders:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

    async updateOrderStatus(orderId: number | string, status: string): Promise<OrderOutput> {
        try {
            const params: Record<string, string> = {
                order_id: String(orderId),
                status: status
            };

            const response = await this.axiosInstance.put<OrderOutput>(`/orders/status`, null, { params });

            return response.data;
        } catch (error) {
            console.error('Error updating order status:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

    async deleteOrder(orderId: number | string): Promise<OrderOutput> {
        try {
            const response = await this.axiosInstance.delete<OrderOutput>(`/orders/${orderId}`);
            return response.data;
        } catch (error) {
            console.error('Error deleting order:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }
}

export default OrderService;