import React, { useContext, useEffect, useState } from 'react';
import { AppBar, Button, IconButton, Menu, MenuItem, SvgIcon, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Stack } from '@mui/system';
import InstagramIcon from '@mui/icons-material/Instagram';
import { ShoppingCartContext } from '../context/cartContext';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../context/securityContext';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { ShopOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Face3Icon from '@mui/icons-material/Face3';

const countStyle: React.CSSProperties = {
  position: 'absolute',
  top: '-5px',
  right: '-2px',
  backgroundColor: '#ff5722',
  borderRadius: '50%',
  width: '20px',
  height: '20px',
  fontSize: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white'
};

const DropdownMenu = () => {
  const { isAuthenticated, logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const { countProducts } = useContext(ShoppingCartContext);

  const theme = useTheme();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack direction={"row"} alignItems={"center"} spacing={1}>
      <IconButton
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <Link to='/shop' style={{ textDecoration: 'none', color: 'inherit' }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <ShopOutlined fontSize="small" sx={{ color: theme.palette.primary.main }} />
              <Typography variant="body1">Tienda</Typography>
            </Stack>
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to='/about-me' style={{ textDecoration: 'none', color: 'inherit' }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Face3Icon fontSize="small" sx={{ color: theme.palette.primary.main }} />
              <Typography variant="body1">Sobre Mi</Typography>
            </Stack>
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to='/contact' style={{ textDecoration: 'none', color: 'inherit' }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <MailOutlineIcon fontSize="small" sx={{ color: theme.palette.primary.main }} />
              <Typography variant="body1">Contacto</Typography>
            </Stack>
          </Link>
        </MenuItem>
        {isAuthenticated && (
          <MenuItem onClick={handleClose}>
            <Link to='/admin' style={{ textDecoration: 'none', color: 'inherit' }}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <AdminPanelSettingsIcon fontSize="small" sx={{ color: theme.palette.primary.main }} />
                <Typography variant="body1">Administración</Typography>
              </Stack>
            </Link>
          </MenuItem>
        )}
        {isAuthenticated && (
          <MenuItem onClick={() => {
            logout();
            handleClose();
          }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <LogoutIcon fontSize="small" sx={{ color: theme.palette.primary.main }} />
              <Typography variant="body1">Salir</Typography>
            </Stack>
          </MenuItem>
        )}
      </Menu>
      <Button color="inherit" href="/cart"><ShoppingCartIcon /><span style={countStyle}>{countProducts()}</span></Button>
    </Stack>
  );
};

const NavBar = () => {
  const { isAuthenticated, logout } = useAuth();
  const theme = useTheme();
  const { countProducts } = useContext(ShoppingCartContext);

  const transparentNavBar = {
    bgcolor: "white",
    zindex: '0',
    color: theme.palette.text.primary,
    boxShadow: 'none',
    backDropFilter: 'blur(10px)'
  };

  const scrollNavBar = {
    bgcolor: "white",
    color: theme.palette.text.primary,
  };

  const [navBarScroll, setNavBarColor] = useState<boolean>(false); // Initial color
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const threshold = 0.05 * window.innerHeight; // 10% of window height
    const scroll = scrollY > threshold;

    setNavBarColor(scroll);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <AppBar position="fixed" sx={navBarScroll || isSmallScreen ? scrollNavBar : transparentNavBar}>
      <Toolbar sx={{ p: 2 }}>
        <Stack direction="row" spacing={2} alignItems={"center"} sx={{ flexGrow: 1 }}>
          <Link to='/'>
            <img src="/images/logo/NayadeLifeLogo.png" alt="NadaLife Logo" style={{ width: isSmallScreen ? 60 : 100, height: 'auto' }} />
          </Link>
          {!isSmallScreen && (
            <Stack direction="row" spacing={2} alignItems={"center"} sx={{ flexGrow: 1 }}>
              <Typography>~☀️NayadeLife🌙~</Typography>
              <a href="https://www.instagram.com/nayadelife/" target='_blank' style={{ color: "#833AB4" }}>
                <InstagramIcon fontSize='large' />
              </a>
            </Stack>
          )}
        </Stack>
        {isSmallScreen ? (
          <DropdownMenu />
        ) : (
          <div>
            <Link to='/shop' style={{ textDecoration: 'none', color: 'inherit' }}>
              <Button color="inherit">
                <Stack alignItems={"center"} spacing={0.5} direction={"row"}>
                  <ShopOutlined sx={{ color: theme.palette.primary.main }} /><Typography variant='body2'>Tienda</Typography>
                </Stack>
              </Button>
            </Link>
            <Link to='/about-me' style={{ textDecoration: 'none', color: 'inherit' }}>
              <Button color="inherit">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Face3Icon fontSize="small" sx={{ color: theme.palette.primary.main }} />
                  <Typography variant="body1">Sobre Mi</Typography>
                </Stack>
              </Button>
            </Link>

            <Link to='/contact' style={{ textDecoration: 'none', color: 'inherit' }}>
              <Button color="inherit">
                <Stack alignItems={"center"} spacing={0.5} direction={"row"}>
                  <MailOutlineIcon sx={{ color: theme.palette.primary.main }} /><Typography variant='body2'>Contacto</Typography>
                </Stack>
              </Button>
            </Link>
            {isAuthenticated && (
              <>
                <Link to='/admin' style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Button color="inherit">
                    <Stack alignItems={"center"} direction={"row"} spacing={0.5}>
                      <AdminPanelSettingsIcon sx={{ color: theme.palette.primary.main }} /><Typography variant='body2'>Administración</Typography>
                    </Stack>
                  </Button>
                </Link>
                <Button color="inherit" onClick={() => { logout(); }}>
                  <LogoutIcon sx={{ color: theme.palette.primary.main }} />Salir
                </Button>
              </>
            )}
            <Link to='/cart' style={{ textDecoration: 'none', color: 'inherit' }}>
              <Button color="inherit">
                <ShoppingCartIcon /><span style={countStyle}>{countProducts()}</span>
              </Button>
            </Link>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
