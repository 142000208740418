import { AxiosError, AxiosInstance } from "axios";
import { ApiConfig } from "./apiConfig";
import { ProductData, ProductInput, ProductPortrait, ProductsPortraitResult, ProductUpdate } from "../models/product";

class ProductService {
    axiosInstance: AxiosInstance;

    constructor(apiConfig: ApiConfig) {
        this.axiosInstance = apiConfig.axiosInstance;
    }

    async getProducts(categoryId?: string | number): Promise<ProductData[]> {
        try {
            let url = '/products';
            if (categoryId) {
                url += `?category_id=${categoryId}`;
            }

            const response = await this.axiosInstance.get(url);
            return response.data;
        } catch (error) {
            console.error('Error getting product:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

    async getProductsPortraits(categoryId?: string | number, skip: number = 0, limit: number = 20): Promise<ProductsPortraitResult> {
        try {
            let url = `/products_portraits?skip=${skip}&limit=${limit}`;
            if (categoryId) {
                url += `&category_id=${categoryId}`;
            }

            const response = await this.axiosInstance.get(url);
            return response.data;
        } catch (error) {
            console.error('Error getting product:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

    async getProduct(productId: string | number): Promise<ProductData> {
        try {
            const response = await this.axiosInstance.get(`/products/${productId}`);
            return response.data;
        } catch (error) {
            let err = error as AxiosError;
            console.log('Error code:', err.code);  // Log the error code
            console.log('Error response:', err.response);  // Log the error response details
            throw error;  // Rethrow the error for handling in the caller
        }
    }

    async createProduct(productInput: ProductInput): Promise<ProductData> {
        const formData = new FormData();
        formData.append('name', productInput.name);
        formData.append('short_description', productInput.short_description);
        formData.append('main_description', productInput.main_description);
        formData.append('price', productInput.price.toString());
        formData.append('sold', productInput.sold.toString());
        formData.append('category_id', productInput.category_id.toString());
        formData.append('instagram_link', productInput.instagram_link ?? ''); // Add conditional check for null or undefined
        if (productInput.main_image_file !== null) {
            formData.append('main_image', productInput.main_image_file);
        }

        // Append secondary images
        for (const image of productInput.secondary_images_files) {
            formData.append('secondary_images', image);
        }
        try {
            const response = await this.axiosInstance.post('/products', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            return response.data;
        } catch (error) {
            console.error('Error creating product:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

    async updateProduct(productUpdate: ProductUpdate): Promise<ProductData> {
        const formData = new FormData();
        formData.append('name', productUpdate.name);
        formData.append('short_description', productUpdate.short_description);
        formData.append('main_description', productUpdate.main_description);
        formData.append('price', productUpdate.price.toString());
        formData.append('sold', productUpdate.sold.toString());
        formData.append('category_id', productUpdate.category_id.toString());
        formData.append('instagram_link', productUpdate.instagram_link ?? '');

        if (productUpdate.main_image_id !== null) {
            formData.append('main_image_id', productUpdate.main_image_id.toString());
        }
        // Append secondary images IDs
        for (const image of productUpdate.secondary_images_ids) {
            formData.append('secondary_images_ids', image.toString());
        }
        // Add conditional check for null or undefined
        if (productUpdate.main_image_file !== null) {
            formData.append('main_image_file', productUpdate.main_image_file);
        }
        // Append secondary images
        if (Array.isArray(productUpdate.secondary_images_files)) {
            for (const image of productUpdate.secondary_images_files) {
                formData.append('secondary_images_files', image);  // Append each file individually
            }
        } else {
            console.error("secondary_images_files is not an array");
        }

        // Log the formData content for debugging
        formData.forEach((value, key) => {
            console.log(key, value);
        });

        try {
            const response = await this.axiosInstance.put(`/products/${productUpdate.product_id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            return response.data;
        } catch (error) {
            console.error('Error creating product:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

    async deleteProduct(productId: number | string) {
        try {
            const response = await this.axiosInstance.delete(`/products/${productId}`);
            return response.data;
        } catch (error) {
            console.error('Error deleting product:', error);
            throw error; // Rethrow the error for handling in the caller
        }
    }

}

export default ProductService;